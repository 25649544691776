export const actualRoutes = [
  {
    name: 'About National Games',
    containsMenu: true,
    menu: [
      {
        name: 'History',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/7e00994ac96e4b5e825e3907c38024d3',
      },
      {
        name: 'IOA',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/3a2c27434f7d41e7807cf23d23c56567',
      },
      {
        name: 'Kerala 2015',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/b398607752454f04a6bef57f72d996f8',
      },
    ],
  },
  {
    name: 'NG Gujarat 2022',
    containsMenu: true,
    menu: [
      {
        name: 'Schedule',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/5a8ac9985b16405db3ee7ee3820a75bf',
      },
      {
        name: 'Sports',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/sports',
      },
      {
        name: 'Venues',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/venues',
      },
      {
        name: 'Mascot',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/224ea5c94a96489e8bb6addff9686768',
      },
      {
        name: 'Anthem',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/bc52068468184edaac4a65c81f3c66a5',
      },
      {
        name: 'Sporting Heroes',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/40ac3dd920a5449dadf801c3b95c17df',
      },
      {
        name: 'Participating States / UT / Unit',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/58f14d8a9dea47d38d72876446e9bbcd',
      },
      // {
      //   name: 'Participating States/UT',
      //   route: '/micro-site/221/landing/embed-page/58f14d8a9dea47d38d72876446e9bbcd',
      // },
      {
        name: 'Games Secretariat',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/6a321dd0d58f48388fa02acd2d04d41c',
      },
      // {
      //   name: 'NSF',
      //   route: '/micro-site/221/landing/embed-page/ca316c6aa6f74295b90778a751fdf351',
      // },
      // {
      //   name: 'Partners',
      //   route: '',
      // },
      {
        name: 'NGG 2022 App',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/9940802fcfe64e038809af3c0410e298',
      },
      {
        name: 'The Organisation',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/a14ee8f4cbf4472593f8e1a7e9b82d51',
      },
      // {
      //   name: 'Contact Us',
      //   route: '',
      // },
    ],
  },
  {
    name: 'Schedule',
    ignoreBaseRoute: true,
    route: '/micro-site/221/landing/embed-page/5a8ac9985b16405db3ee7ee3820a75bf',
  },
  {
    name: 'Fixtures & Results',
    ignoreBaseRoute: true,
    route: '/micro-site/221/landing/draws-schedules',
  },
  {
    name: 'General Info',
    containsMenu: true,
    menu: [
      {
        name: 'Accreditation',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/02ca35b6e9794d6796f051fb601e97b7',
      },
      {
        name: 'Accommodation',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/a03ba2a8d4044ee3860f07d34018b6f3',
      },
      {
        name: 'Catering',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/0c4cd2a4dc844eaf963bfedb569f220b',
      },
      {
        name: 'Transportation',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/8dbfdf4d40d74c5296f84ba51c6d5dc2',
      },
      {
        name: 'Medical Services',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/251aff4a21104abdaaed51d7fcf4aa6e',
      },
      {
        name: 'Emergency Number',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/embed-page/62317d6fc42f44d18015f94c277e1af3',
      },
    ],
  },
  {
    name: 'Media & Gallery',
    containsMenu: true,
    menu: [
      {
        name: 'Photos',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/gallery',
      },
      {
        name: 'Videos',
        ignoreBaseRoute: true,
        route: '/micro-site/221/landing/gallery',
      },
      // {
      //   name: 'Gujrat Sporting Heroes',
      //   route: '/micro-site/221/landing/embed-page/40ac3dd920a5449dadf801c3b95c17df',
      // },
      // {
      //   name: 'Media Collaterals',
      //   route: '/micro-site/221/landing/embed-page/95ee0e68adec47e8a3bb81809e165112',
      // },
      // {
      //   name: 'Media Help',
      //   route: '/micro-site/221/landing/embed-page/6a321dd0d58f48388fa02acd2d04d41c',
      // },
    ],
  },
  // {
  //   name: 'Medal Tally',
  //   ignoreBaseRoute: true,
  //   route: '/micro-site/221/landing/results',
  // },
  {
    name: 'Download NGG 2022 APP',
    ignoreBaseRoute: true,
    route: '/micro-site/221/landing/embed-page/9940802fcfe64e038809af3c0410e298',
  },
  // {
  //   name: 'Contact Us',
  //   ignoreBaseRoute: true,
  //   route: '',
  // },
];

// Navbar main tags

// About National Games
export const aboutTab = {
  name: 'About National Games',
  menu: [
    {
      name: 'History',
      route: '/micro-site/221/landing/embed-page/7e00994ac96e4b5e825e3907c38024d3',
    },
    {
      name: 'Kerala 2015',
      route: '/micro-site/221/landing/embed-page/b398607752454f04a6bef57f72d996f8',
    },
  ],
};

// NG Gujarat 2022
export const NGGTab = {
  name: 'NG Gujarat 2022',
  menu: [
    {
      name: 'Schedule',
      ignoreBaseRoute: true,
      route: '/micro-site/221/landing/embed-page/5a8ac9985b16405db3ee7ee3820a75bf',
    },
    {
      name: 'Sports',
      route: '/micro-site/221/landing/sports',
    },
    {
      name: 'Venues',
      route: '/micro-site/221/landing/venues',
    },
    {
      name: 'Mascot',
      route: '/micro-site/221/landing/embed-page/224ea5c94a96489e8bb6addff9686768',
    },
    {
      name: 'Anthem',
      route: '/micro-site/221/landing/embed-page/bc52068468184edaac4a65c81f3c66a5',
    },
    // {
    //   name: 'Participating States/UT',
    //   route: '/micro-site/221/landing/embed-page/58f14d8a9dea47d38d72876446e9bbcd',
    // },
    // {
    //   name: 'NSF',
    //   route: '/micro-site/221/landing/embed-page/ca316c6aa6f74295b90778a751fdf351',
    // },
    // {
    //   name: 'Partners',
    //   route: '',
    // },
    {
      name: 'Games Secretariat',
      route: '/micro-site/221/landing/embed-page/6a321dd0d58f48388fa02acd2d04d41c',
    },
    {
      name: 'NGG 2022 App',
      route: '/micro-site/221/landing/embed-page/9940802fcfe64e038809af3c0410e298',
    },
    {
      name: 'The Organisation',
      route: '/micro-site/221/landing/embed-page/a14ee8f4cbf4472593f8e1a7e9b82d51',
    },
    // {
    //   name: 'Contact Us',
    //   route: '',
    // },
  ],
};

// Media & Gallery
export const mediaNGallary = {
  name: 'Media & Gallery',
  menu: [
    {
      name: 'Photos',
      route: '/micro-site/221/landing/gallery',
    },
    {
      name: 'Videos',
      route: '/micro-site/221/landing/gallery',
    },
    {
      name: 'Gujrat Sporting Heroes',
      route: '/micro-site/221/landing/embed-page/40ac3dd920a5449dadf801c3b95c17df',
    },
    {
      name: 'Media Collaterals',
      route: '/micro-site/221/landing/embed-page/95ee0e68adec47e8a3bb81809e165112',
    },
    // {
    //   name: 'Gujrat Sporting Heroes',
    //   route: '/micro-site/221/landing/embed-page/40ac3dd920a5449dadf801c3b95c17df',
    // },
  ],
};
