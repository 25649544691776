import React from 'react';
// import Helmet from 'react-helmet';
import kheloSchedule from '../../../assets/img/Web_schedule.jpg';
import kheloScheduleMobile from '../../../assets/img/Mobile_schedule.jpg';

export default function index() {
  return (
    <div className="w-full md:px-0 px-4 mb-2">
      {/* <Helmet>
        <title>Schedule | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Check the SCHEDULE now! 25 Sports | LIVE from 4th - 13th June 2022. Watch India's finest U/18 athletes represent their respective states at #KIYG2021!"
        />
        <meta property="og:title" content="Schedule | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Check the SCHEDULE now! 25 Sports | LIVE from 4th - 13th June 2022. Watch India's finest U/18 athletes represent their respective states at #KIYG2021!"
        />
      </Helmet> */}
      {/* <div className="py-2">
        <a target="_blank" rel="noreferrer" href={kheloSchedule} download>
          Click here to download Full Schedule
        </a>
      </div> */}
      <img className="m-auto hidden md:block" src={kheloSchedule} />
      <img className="m-auto md:hidden" src={kheloScheduleMobile} />
    </div>
  );
}
