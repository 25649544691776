import React, { useState, useRef } from 'react';
import LogoWhiteOutlined from '../../../assets/img/logo/Logo.jpg';
import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { useUserProfile } from 'profiles/queries/profileHooks';
import { logOut } from 'authentication/services/authentication';
import menuIcon from '../../../assets/img/menuBlue.png';
import crossIcon from '../../../assets/img/Khelo Cross Hamburger.svg';
import gujratTurism from '../../../assets/img/GujratTurisum.svg';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { MobDropdown, ProfileDropdown } from './Dropdown';
// import Dropdown, { MobDropdown, ProfileDropdown } from './Dropdown';
import { useOutsideClick } from 'utils/HooksUtils';
import { actualRoutes } from './allRoutes';
// import { aboutTab, actualRoutes, mediaNGallary, NGGTab } from './allRoutes';
// import { nanoid } from 'nanoid';
import downloadApp from '../../../assets/img/NGGAppDownload2.png';
import downloadAppM from '../../../assets/img/NGGAppDownload.png';
// import Navbar from '../Navbar';
import MenuNav from '../Dropdown/MenuNav';
import '../Dropdown/styles.css';

const KheloHeader = ({
  fromMicroSite,
  fromDigitalScoring,
  fromRegister,
  invisibleLinks,
  hideLogin,
}) => {
  const userProfileQuery = useUserProfile(undefined, !invisibleLinks);
  const match = useRouteMatch();
  // To get the window size
  const windowWidth = useWindowDimensions();
  const [openNavbar, setOpenNavbar] = useState(false);
  const divRef = useRef(null);
  useOutsideClick(divRef, () => setOpenNavbar(false));

  const history = useHistory();

  const profileTab = [
    {
      name: 'Matches',
      route: `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/match-fixtures-results`,
    },
    {
      name: 'Arrival/Stay',
      route: `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/accomodation`,
    },
    {
      name: 'Food/Travel',
      route: `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/transport`,
    },
    {
      name: 'Accred & Kit',
      route: `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/accreditation`,
    },
  ];
  if (userProfileQuery.isSuccess && userProfileQuery?.data?.message?.primary_role === 'coach') {
    profileTab.splice(
      4,
      0,
      {
        name: 'Contingents',
        route: `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/contingents`,
      },
      {
        name: 'My Teams',
        route: `/${userProfileQuery?.data?.message?.primary_role}/profiles/tournaments/221/events`,
      }
    );
  }
  return (
    <>
      <div className="bg-white font-montserrat sticky top-0 z-20">
        {windowWidth.width >= 1024 ? (
          <>
            <div className="w-full flex justify-end">
              <NavLink
                to="/micro-site/221/landing/embed-page/9940802fcfe64e038809af3c0410e298"
                className="text-base font-bold m-1 mr-4"
              >
                <img src={downloadApp} className="h-5" />
              </NavLink>
            </div>
            <div className="flex h-24 w-full">
              <Link
                to={`/`}
                className="border-1 h-full w-42 p-1 flex justify-center content-center"
              >
                <img
                  src={LogoWhiteOutlined}
                  className={'w-full m-auto lg:inline-block cursor-pointer'}
                  alt={''}
                />
              </Link>
              <div className="w-full h-full">
                <div className="border-t-1 text-center flex w-full h-14">
                  <div className="border-r-1 h-full w-32 flex justify-center items-center">
                    {/* <NavLink to="/micro-site/221/landing/embed-page/5a8ac9985b16405db3ee7ee3820a75bf"> */}
                    <NavLink to="/micro-site/221/landing/results">
                      <span className="font-semibold text-blue-navBg font-montserrat">
                        Medal Tally
                        {/* Schedule */}
                      </span>
                    </NavLink>
                  </div>
                  <div className="flex font-semibold text-base justify-center items-center w-full nav-area">
                    <MenuNav />
                    {/* <Dropdown title={aboutTab.name} menu={aboutTab.menu} key={nanoid()} />
                    <Dropdown title={NGGTab.name} menu={NGGTab.menu} key={nanoid()} />
                    <Dropdown title={mediaNGallary.name} menu={mediaNGallary.menu} key={nanoid()} />
                    <NavLink to="/micro-site/221/landing/draws-schedules">
                      <div className="text-blue-navBg font-montserrat font-bold">
                        Match Fixtures & Results
                      </div>
                    </NavLink> */}
                  </div>
                  <div className="h-full w-24 border-l-1">
                    <Link
                      to="/micro-site/221/landing/embed-page/6ce328a28fcd45fa9ced6d8ebe7d0481"
                      className="flex w-full h-full justify-center items-center"
                    >
                      <img src={gujratTurism} alt={''} className="w-11" />
                    </Link>
                  </div>
                  <div className="flex justify-center items-center border-l-1 h-full w-32">
                    {userProfileQuery.isSuccess ? (
                      <ProfileDropdown userProfileQuery={userProfileQuery} />
                    ) : (
                      !hideLogin && (
                        <button
                          className={'cursor-pointer rounded-lg px-3 py-1 bg-pink-navTxt'}
                          onClick={() => history.push('/signin')}
                        >
                          <span className={'text-sm font-montserrat font-bold text-white truncate'}>
                            Sign In
                          </span>
                        </button>
                      )
                    )}
                  </div>
                </div>
                <div className="text-center text-white bg-blue-navBg flex h-10 w-full">
                  <NavLink
                    to="/micro-site/221/landing/embed-page/5a8ac9985b16405db3ee7ee3820a75bf"
                    className={'flex justify-center items-center w-full h-full'}
                  >
                    <div className="flex items-center justify-center border-r-1 w-full h-full text-white font-montserrat hover:bg-white hover:text-blue-navBg hover:border-blue-navBg border-1">
                      Schedule
                    </div>
                  </NavLink>
                  <NavLink
                    to="/micro-site/221/landing/sports"
                    className={'flex justify-center items-center w-full h-full'}
                  >
                    <div className="flex items-center justify-center border-r-1 w-full h-full text-white font-montserrat hover:border-blue-navBg hover:bg-pink-navTxt border-1">
                      Sports
                    </div>
                  </NavLink>
                  <NavLink
                    to="/micro-site/221/landing/venues"
                    className={'flex justify-center items-center w-full h-full'}
                  >
                    <div className="flex items-center justify-center border-r-1 w-full font-montserrat h-full text-white hover:bg-pink-navTxt hover:border-blue-navBg border-1">
                      Venues
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center justify-between w-full h-9 bg-white">
              <div className="flex w-full h-full items-center">
                <NavLink
                  to="/micro-site/221/landing/embed-page/9940802fcfe64e038809af3c0410e298"
                  className="m-3"
                >
                  <img src={downloadAppM} className="h-6" />
                </NavLink>
              </div>
              {!invisibleLinks ? (
                <img
                  src={menuIcon}
                  className="w-4 mr-4 lg:hidden"
                  onClick={() => setOpenNavbar(true)}
                  alt={''}
                />
              ) : null}
            </div>
            <div className="flex items-center overflow-hidden font-semibold h-18 text-sm ">
              <div className="grow-3 h-full" style={{ width: `calc(50% - 56px)` }}>
                {/* <NavLink to={'/micro-site/221/landing/embed-page/5a8ac9985b16405db3ee7ee3820a75bf'}> */}
                <NavLink to={'/micro-site/221/landing/results'}>
                  <div className="flex justify-center items-center h-10 border-t-1 text-blue-navBg ">
                    <span className="text-blue-navBg font-montserrat font-semibold">
                      Medal Tally
                      {/* Schedule */}
                    </span>
                  </div>
                </NavLink>
                <NavLink to={'/micro-site/221/landing/sports'}>
                  <div className="flex justify-center items-center mt-1 h-8 w-full text-white  font-montserrat font-semibold bg-blue-navBg">
                    Sports
                  </div>
                </NavLink>
              </div>
              <Link
                to={
                  fromMicroSite
                    ? `${match.url}/landing/overview`
                    : fromRegister
                    ? `/micro-site/221/landing`
                    : `/${userProfileQuery?.data?.message?.primary_role}/profiles/profile`
                }
                className="grow-1 border-1 h-full w-28 flex justify-center content-center"
              >
                <img
                  src={LogoWhiteOutlined}
                  className={'w-full m-auto cursor-pointer p-1'}
                  alt={''}
                />
              </Link>
              <div className="h-full grow-3 overflow-hidden" style={{ width: `calc(50% - 56px)` }}>
                <NavLink to={'/micro-site/221/landing/draws-schedules'}>
                  <div className="flex justify-center items-center h-10 border-t-1 text-blue-navBg ">
                    <span className="text-blue-navBg font-montserrat font-semibold text-center">
                      Fixtures & Results
                    </span>
                  </div>
                </NavLink>
                <NavLink to={'/micro-site/221/landing/venues'}>
                  <div className="flex justify-center items-center mt-1 h-8 w-full text-white  font-montserrat font-semibold bg-blue-navBg">
                    Venues
                  </div>
                </NavLink>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className={`bg-white min-h-screen max-h-screen font-montserrat w-${
          windowWidth.width > 768 ? '1/2' : '4/5'
        } shadow-2xl fixed overflow-y-scroll top-0 z-40 lg:hidden ${
          !openNavbar ? '-right-full' : 'right-0'
        } transition-all duration-500 flex flex-col justify-between`}
        ref={divRef}
      >
        <div className="h-full w-full">
          <div className="w-full h-22 flex">
            <Link
              to={
                fromMicroSite
                  ? `${match.url}/landing/overview`
                  : fromRegister
                  ? `/micro-site/221/landing`
                  : `/${userProfileQuery?.data?.message?.primary_role}/profiles/profile`
              }
              className="grow-1 border-1 h-full flex justify-center content-center"
            >
              <img src={LogoWhiteOutlined} className={'m-auto w-48 p-2 cursor-pointer'} alt={''} />
            </Link>
            <div className="w-full flex flex-col justify-between items-end">
              <img
                src={crossIcon}
                className="w-9 p-2 mr-3"
                onClick={() => setOpenNavbar(false)}
                alt={''}
              />
              {userProfileQuery.isSuccess ? (
                <div
                  className="text-white text-sm w-full h-12 bg-pink-navTxt flex justify-center items-center font-bold "
                  onClick={() => logOut(userProfileQuery?.data)}
                >
                  Log Out
                </div>
              ) : !hideLogin ? (
                <div
                  className="text-white text-sm w-full h-12 bg-pink-navTxt flex justify-center items-center font-bold "
                  onClick={() => history.push('/signin')}
                >
                  Sign In
                </div>
              ) : null}
            </div>
          </div>
          <div className="h-full mb-14">
            <div className=" flex flex-col">
              {userProfileQuery.isSuccess &&
              userProfileQuery?.data?.message?.primary_role === 'catering_verifier' ? (
                <>
                  <Link
                    onClick={() => setOpenNavbar(false)}
                    to={'/catering_verifier/profiles/profile'}
                    className="border-1 text-blue-navBg text-sm font-bold h-12 pl-6 px-3 flex items-center"
                  >
                    <span className="text-blue-navBg font-montserrat">Profile</span>
                  </Link>
                </>
              ) : (
                <>
                  <MobDropdown
                    title={'Profile'}
                    userProfileQuery={userProfileQuery}
                    setOpenNavbar={setOpenNavbar}
                    menu={profileTab}
                    isProfile
                  />
                </>
              )}
              {actualRoutes.map((route, i) =>
                route.containsMenu ? (
                  <MobDropdown
                    title={route.name}
                    userProfileQuery={userProfileQuery}
                    setOpenNavbar={setOpenNavbar}
                    menu={route.menu}
                  />
                ) : route.appLink ? (
                  <a
                    onClick={() => setOpenNavbar(false)}
                    key={i}
                    target="_blank"
                    rel="noreferrer"
                    href={route.route}
                    className="border-1 text-blue-navBg text-sm font-bold h-12 pl-6 px-3 flex items-center"
                  >
                    <span className="text-blue-navBg font-montserrat">{route.name}</span>
                  </a>
                ) : (
                  <Link
                    onClick={() => setOpenNavbar(false)}
                    key={i}
                    to={
                      !route.ignoreBaseRoute ? `${match.url}/landing/${route.route}` : route.route
                    }
                    className="border-1 text-blue-navBg text-sm font-bold h-12 pl-6 px-3 flex items-center"
                  >
                    <span className="text-blue-navBg font-montserrat">{route.name}</span>
                  </Link>
                )
              )}
            </div>
          </div>
        </div>
        <>
          <Link
            to="/micro-site/221/landing/embed-page/6ce328a28fcd45fa9ced6d8ebe7d0481"
            className="flex w-full justify-start content-center"
          >
            <span className="flex font-semibold items-center mr-5 font-montserrat">
              <img src={gujratTurism} alt={''} />
              <p className="text-xs text-orange-navGT">Gujrat Tourism</p>
            </span>
          </Link>
        </>
      </div>
    </>
  );
};

export default KheloHeader;
